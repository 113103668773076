import React from "react"
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  InfiniteHits,
  Stats,
  Configure,
} from "react-instantsearch-dom"
import { connectSearchBox } from "react-instantsearch-dom"

import Layout from '../components/layout'
import SEO from '../components/seo'

// Redux
import {
  invertHeader,
  resetHeader,
  setSearchString,
  initialSearch,
} from "../actions"
import { connect } from "react-redux"

import { boxFormats } from "../components/FigureBox"
import ContentPreview, {
  ContentPreviewLayouts,
} from "../content/preview/ContentPreview"

const MIN_CHAR = 3

//
// the actual search box
//

class MySearchBox extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  handleChange(e) {
    this.props.refine(e.target.value)
  }

  handleKeyUp(e) {
    this.props.onKeyUp(e)
  }

  handleRefine(e) {
    this.props.refine(this.refs.search.value)
  }

  //
  // FIXME: handle onChange with a debounce delay
  //

  render() {
    return (
      <input
        ref="search"
        type="text"
        className="search_page__input"
        value={this.props.currentRefinement}
        onKeyUp={this.handleKeyUp}
        onChange={this.handleChange}
        tabIndex={0}
        placeholder=" Type your search here..."
      />
    )
  }
}

const ConnectedSearchBox = connectSearchBox(MySearchBox)

//
// each search result hits
//

class SearchResultHit extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let key = Math.floor(Math.random() * 1024 * (Math.random() * 1024))
    // console.log(this.props)
    return (
      <ContentPreview
        key={key}
        contentData={this.props.hit}
        contentType={
          this.props.hit.displayOnWorking
            ? `culture`
            : this.props.hit.contentType
        }
        layout={ContentPreviewLayouts.small}
        boxFormat={boxFormats.postalCard}
        reveals={true}
        effect="effects__fade_down"
      />
    )
  }
}

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.searchInputDOM = null
    this.change = this.change.bind(this)
    this.state = {
      searchString: ``,
      hasResultsClassName: ``,
      inputHasSubmited: false,
    }
  }

  componentDidMount() {
    this.setValue(this.props.SearchState.searchString)
    this.props.setSearchString(
      this.state.searchString,
      this.hasSearched(),
      true
    )
    this.props.invertHeader()
  }

  componentWillUnmount() {
    this.props.resetHeader()
    this.setValue(this.props.SearchState.searchString)
    this.props.setSearchString(
      this.state.searchString,
      this.hasSearched(),
      false
    )
  }

  componentDidUpdate(prevProps, prevState) {
    // handles when reseting search
    if (
      this.props.SearchState.reset &&
      prevProps.SearchState.reset !== this.props.SearchState.reset
    ) {
      this.setValue(``)
      this.props.initialSearch()
    }

    if (
      prevState.searchString !== this.state.searchString ||
      prevState.inputHasSubmited !== this.state.inputHasSubmited
    ) {
      // send current state.searchString to reducer
      this.props.setSearchString(
        this.state.searchString,
        this.hasSearched(),
        true
      )

      // show or hide search results
      if (this.hasSearched()) {
        this.props.resetHeader()
        this.setState({
          hasResultsClassName: `has-search-results`,
        })
      } else {
        this.props.invertHeader()
        this.setState({
          hasResultsClassName: ``,
          inputHasSubmited: false,
        })
      }
    }
  }

  hasSearched() {
    // can't search if empty
    return (
      this.state.searchString.length &&
      (this.state.searchString.length >= MIN_CHAR ||
        this.state.inputHasSubmited)
    )
  }

  change(event, value = false) {
    // enter key
    if (event && event.keyCode === 13) {
      // blur input
      event.currentTarget.blur()

      // change state
      this.setState({
        inputHasSubmited: true,
        searchString:
          typeof value === `string` ? value : event.currentTarget.value,
      })
    } else {
      // set as state
      this.setState({
        searchString:
          typeof value === `string` ? value : event.currentTarget.value,
      })
    }
  }

  setValue(value = ``) {
    if (typeof value === `string`) {
      // this.refs.searchInput.refine(value)
      this.change(null, value)
    }
  }

  render() {
    // console.log(this.refs.searchInput)
    return (
        <Layout>
        <SEO title={`Search`}/>
        <section
            className={`search_page container-fluid fade-in ${
            this.state.hasResultsClassName
            }`}
        >

            <div className="row">
            <InstantSearch
                ref="search"
                searchClient={algoliasearch(
                  process.env.GATSBY_ALGOLIA_APPLICATION_ID,
                  process.env.GATSBY_ALGOLIA_API_KEY
                )}
                indexName={`${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}${process.env.GATSBY_ALGOLIA_INDEX_NAME
                }`}
            >
                <Configure hitsPerPage={12} />
                <div className="col-12">
                <ConnectedSearchBox
                    onKeyUp={this.change}
                    onSubmit={this.change}
                    ref="searchInput"
                />
                </div>
                <div className={`search_page__results col-12`}>
                <Stats
                    translations={{
                    stats: (n, ms) => `${n.toLocaleString()} results found.`,
                    }}
                />
                <InfiniteHits
                    className={`search_page__results__hits`}
                    hitComponent={SearchResultHit}
                />
                </div>
            </InstantSearch>
            </div>
        </section>
      </Layout>
    )
  }
}

const mapStateToProps = store => {
  return {
    SiteHeaderState: store.SiteHeaderState,
    SearchState: store.SearchState,
  }
}

export default connect(
  mapStateToProps,
  {
    invertHeader,
    resetHeader,
    setSearchString,
    initialSearch,
  }
)(Search)

